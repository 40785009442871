// @ts-nocheck
import { launchAsync } from '@microsoft/immersive-reader-sdk';
import throttle from 'lodash-es/throttle';

import { immersiveReaderHtmlDecoder } from '../../helper/formHelper';
import { clientFetch } from '~/helper/helper.client';

const immersiveReaderHashValue = 'ImmersiveReader';

export const immersiveReaderIsOpen = () =>
  !!(typeof window !== 'undefined' && window?.location?.hash.includes(immersiveReaderHashValue));

const sendPostMessageToMobileClients = message => {
  if (window.webkit) window.webkit.messageHandlers.mobileListener.postMessage(message);
  if (typeof AndroidListener !== 'undefined') AndroidListener.postMessage(message);
};

const onExitStandAlone = () => {
  sendPostMessageToMobileClients('ImmersiveReader-CloseReader');
  if (window.webkit) return; // Disable handling for iOS app
  if (this.state.referrer) window.location.assign(decodeURIComponent(this.state.referrer));
  else window.history.back();
};

const setLocationHash = value => {
  if (window && window.location) window.location.hash = value;
};

const immersiveReader = (title, text, options) => {
  if (options.setIsFetching) options.setIsFetching(true);
  setLocationHash(immersiveReaderHashValue);

  const player = document.querySelector('.wvp-videoPlayer');
  if (player) player.pause();

  const content = {
    title: immersiveReaderHtmlDecoder(title),
    chunks: [
      {
        content: immersiveReaderHtmlDecoder(text),
      },
    ],
  };
  const launchAsyncOptions = {
    // Z-index of the iframe that will be created (default is 1000)
    uiZIndex: 1500,
    onExit: () => {
      setLocationHash('');
      if (options.standAlone) onExitStandAlone();
      if (options.idToFocusOnExit) {
        const element = document && document.getElementById(options.idToFocusOnExit);
        if (element) element.focus();
      }
    },
  };

  clientFetch(`${window.location.origin}/immersiveReader/GetTokenAndSubdomain`)
    .then(data => data.json())
    .then(data => {
      if (options.setIsFetching) options.setIsFetching(false);
      if (data.subdomain && data.token) {
        const subdomains = data.subdomain.split(',');
        const randomDomain = subdomains[Math.floor(Math.random() * subdomains.length)];
        launchAsync(data.token, randomDomain, content, launchAsyncOptions)
          .then(() => {
            const iframe = document.querySelector('iframe');
            if (window.webkit && iframe) {
              iframe.classList.add('immersiveReaderStandalone__iosApp');
            }
            sendPostMessageToMobileClients('ImmersiveReader-ReadyForContent');
          })
          .catch(() => {
            logEvent({ name: 'ImmersiveReader::Error' }, { error: 'Immersive Reader Launch Async Error' });
            sendPostMessageToMobileClients('ImmersiveReader-Error');
          });
      }
    })
    .catch(error => {
      logEvent({ name: 'ImmersiveReader::Error' }, { errorMsg: 'Immersive Reader Request Error', error });
      if (options.setIsFetching) options.setIsFetching(false);
    });
};

export const invokeImmersiveReader = throttle(
  (title, text, options = {}) => {
    immersiveReader(title, text, options);
  },
  2000,
  {
    leading: true,
    trailing: false,
  },
);
